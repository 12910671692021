import { HomeContent } from '@luna/screens/home/HomeContent';
import React from 'react';

export function AdminView() {
  // TODO
  return (
    <HomeContent title="Admin">
      <div>Admin</div>
    </HomeContent>
  );
}
